import {defineStore} from "pinia";
import {useApi} from "~/src/api.js";



export const useDevelStore = defineStore({
    id: 'devel',
    state: () => ({
        isMaintenance: false,
        enableBlog: true,
        enableShop: true,
        allowDarkMode: true,
        allowOrder: false,

    }),
    getters: {
    },
    actions: {
        toggleMaintenance() {
          this.isMaintenance = !this.isMaintenance;
        },
        hideMaintenance() {
            this.isMaintenance = false;

        },
        showMaintenance() {
            this.isMaintenance = true;
        },
        async fetchMaintenance() {
            const runtimeConfig = useRuntimeConfig();
            const api = useApi();
            const settings = await api.settings.fetch()
            this.isMaintenance = !!settings.maintenance;
            this.enableBlog = !!settings.enableBlog;
            this.allowOrder = !!settings.allowOrder;
            this.enableShop = !!settings.enableShop;
            this.allowDarkMode = settings?.allowDarkMode || runtimeConfig.public.baseUrl.startsWith('http://localhost')
        }
    }
});
